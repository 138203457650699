import React from 'react';
import Text from '@basicComponents/text';
import {View, StyleSheet} from 'react-native';
import theme from '@style';
import {PayMethod} from './recharge.service';
import LazyImage from '@/components/basic/image';
import {Svg, Path} from 'react-native-svg';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import RechargeTip from './recharge-tip';
import {getChannelGiveStr} from './recharge.utils';

export interface RechargeChannelProps {
  payMethodList: PayMethod[];
  payMethodId?: number;
  onPayMethodChange: (id: number) => void;
}

const RechargeChannel: React.FC<RechargeChannelProps> = ({
  payMethodList,
  payMethodId,
  onPayMethodChange,
}) => {
  const {calcActualSize, screenWidth} = useScreenSize();
  const {i18n} = useTranslation();

  const payMethodItemWidth =
    (screenWidth -
      calcActualSize(theme.paddingSize.l) * 4 -
      calcActualSize(theme.paddingSize.s) * 2) /
    3;
  const payMethodStyles = StyleSheet.create({
    item: {
      width: payMethodItemWidth,
      borderWidth: 1,
      borderColor: theme.backgroundColor.grey,
      paddingHorizontal: calcActualSize(theme.paddingSize.s),
      paddingVertical: calcActualSize(theme.paddingSize.l),
    },
    itemIcon: {
      width: calcActualSize(40),
      height: calcActualSize(40),
    },
    itemSelected: {
      borderColor: theme.basicColor.primary,
    },
    itemSelectedIcon: {
      right: -1,
      bottom: -1,
    },
    activeIconImg: {
      top: 0,
      left: 0,
    },
    activeIcon: {
      width: calcActualSize(42),
      height: calcActualSize(42),
      left: -1,
      top: -1,
      zIndex: 10,
    },
    itemText: {
      width: payMethodItemWidth,
      paddingHorizontal: calcActualSize(theme.paddingSize.s),
    },
    textItem: {
      width: '100%',
    },
    activeIconRatio: {
      textTransform: 'capitalize',
      transform: [
        {rotate: '-45deg'},
        {translateX: calcActualSize(0)},
        {translateY: calcActualSize(-16)},
      ],
      letterSpacing: -0.16,
    },
  });

  const renderDesc = () => {
    const descItem = payMethodList.find(item => item.id === payMethodId);
    if (descItem && descItem.activeDescription) {
      return (
        <>
          {descItem.activeDescription
            .split('\n')
            .filter(v => !!v)
            .map((desc, i) => (
              <View
                style={[
                  payMethodStyles.textItem,
                  theme.flex.flex,
                  theme.flex.row,
                ]}
                key={i}>
                <Text calc fontSize={10} fontWeight="700" accent>
                  {desc.substring(0, 2)}
                </Text>
                <Text calc fontSize={10} fontWeight="500" accent>
                  {desc.substring(2)}
                </Text>
              </View>
            ))}
        </>
      );
    }
    return null;
  };

  return (
    <View
      style={[
        theme.flex.col,
        theme.background.white,
        {
          borderRadius: calcActualSize(theme.borderRadiusSize.m),
          padding: calcActualSize(theme.paddingSize.l),
        },
      ]}>
      <Text calc fontSize={theme.fontSize.m} main>
        {i18n.t('recharge-page.label.channel')}
      </Text>
      <View
        style={[
          theme.flex.row,
          theme.flex.wrap,
          {
            gap: calcActualSize(theme.paddingSize.s),
            marginTop: calcActualSize(theme.paddingSize.s),
          },
        ]}>
        {payMethodList.map((payMethod, index) => {
          return (
            <NativeTouchableOpacity
              key={index}
              onPress={() => onPayMethodChange(payMethod.id)}>
              <View
                key={index}
                style={[
                  payMethodStyles.item,
                  theme.flex.col,
                  theme.flex.center,
                  theme.position.rel,
                  payMethod.id === payMethodId
                    ? payMethodStyles.itemSelected
                    : null,
                  {
                    borderRadius: calcActualSize(theme.borderRadiusSize.xs),
                    opacity: payMethod.disabled ? 0.3 : 1,
                  },
                ]}>
                <RechargeTip>{getChannelGiveStr(payMethod)}</RechargeTip>
                {payMethod.id === payMethodId && (
                  <View
                    style={[
                      theme.position.abs,
                      payMethodStyles.itemSelectedIcon,
                      theme.flex.center,
                    ]}>
                    <Svg
                      width={calcActualSize(20)}
                      height={calcActualSize(20)}
                      viewBox="0 0 20 20"
                      fill="none">
                      <Path
                        d="M10 10L20 0V16C20 18.2091 18.2091 20 16 20H0L10 10Z"
                        fill={theme.basicColor.primary}
                      />
                      <Path
                        d="M11.0003 13.6667L12.9263 15.7407L16.667 12"
                        stroke="white"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Svg>
                  </View>
                )}
                {!!payMethod.activeIcon && (
                  <View
                    style={[theme.position.abs, payMethodStyles.activeIcon]}>
                    <LazyImage
                      imageUrl={payMethod.activeIcon}
                      height={calcActualSize(42)}
                      width={calcActualSize(42)}
                    />
                  </View>
                )}
                {/* {!payMethod.activeIcon && payMethod.ratio && (
                  <View
                    style={[
                      theme.position.abs,
                      payMethodStyles.activeIcon,
                      theme.flex.flex,
                      theme.flex.center,
                    ]}>
                    <View
                      style={[
                        theme.position.abs,
                        payMethodStyles.activeIconImg,
                      ]}>
                      <LazyImage
                        imageUrl={require('@assets/icons/save.webp')}
                        height={calcActualSize(42)}
                        width={calcActualSize(42)}
                      />
                    </View>
                    <Text
                      fontFamily="fontDin"
                      fontSize={8}
                      second
                      color={theme.basicColor.white}
                      fontWeight="900"
                      style={[payMethodStyles.activeIconRatio]}>
                      {`Get ${payMethod.ratio}%`}
                    </Text>
                  </View>
                )} */}
                <View
                  style={[
                    theme.flex.col,
                    theme.flex.centerByCol,
                    {
                      gap: calcActualSize(4),
                    },
                  ]}>
                  <View
                    style={[
                      theme.flex.flex,
                      theme.flex.center,
                      {
                        width: calcActualSize(88),
                        height: calcActualSize(48),
                      },
                    ]}>
                    <LazyImage
                      occupancy="#0000"
                      imageUrl={payMethod.payIcon}
                      width={calcActualSize(88)}
                    />
                  </View>
                  <View
                    style={[
                      theme.flex.row,
                      theme.flex.center,
                      {
                        height: calcActualSize(32),
                      },
                    ]}>
                    <Text
                      fontSize={theme.fontSize.s}
                      second
                      numberOfLines={2}
                      ellipsizeMode="tail"
                      style={[
                        payMethodStyles.itemText,
                        theme.font.center,
                        {
                          lineHeight: calcActualSize(theme.fontSize.l),
                        },
                      ]}>
                      {payMethod.payName}
                    </Text>
                  </View>
                </View>
              </View>
            </NativeTouchableOpacity>
          );
        })}
        {renderDesc()}
      </View>
    </View>
  );
};

export default RechargeChannel;
